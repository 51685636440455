import React from "react";
import Modal from "react-modal";

import "./modal.css";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const ModalPopup = ({ modalIsOpen, setIsOpen }) => {
  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="modal-wrapper">
          <div className="modal-heading">
            <h1>RAISE QUERY</h1>
          </div>
          <div className="form-wrapper">
            <div className="ref-input">
              <label>PropEye Id</label>
              <input type="number" className="ref-control" />
            </div>
            <div className="radio-group">
              <div className="radio-control">
                <input type="radio" name="report" />
                <label> Blank Report Genereated</label>
              </div>
              <div className="radio-control">
                <input type="radio" name="report" />
                <label>Delayed Report Generation</label>
              </div>
            </div>
            <textarea
              rows={3}
              className="text-area"
              placeholder="Add comment"
            />
          </div>
          <div className="button-group">
            <button className="btn-cnl" onClick={() => closeModal()}>
              Cancel
            </button>
            <button className="btn-sbmt">Submit</button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ModalPopup;
