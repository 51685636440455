import React, { useEffect, useState } from "react";

import {
  MapContainer,
  ScaleControl,
  GeoJSON,
  Marker,
  FeatureGroup,
} from "react-leaflet";

import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import Control from 'react-leaflet-custom-control'
import L from "leaflet";
import Legend from "../../components/maplegend/legend";
import { MAP_API_KEY } from "../../utils/url-helper";
import { OnClickEvent } from "../map/OnClickEvent";
import RoutingMachine from "../map/RoutingMachine";
import { hdfcOfficeLocations } from "../../services/explorer-service";
// import { EditControl } from "react-leaflet-draw";
import * as turf from "@turf/turf";

import Pegman from "../pegman/pegman";
import MapPopup from "../mappopup/mapPopup";
import MapList from "../../assets/images/map-list.svg";
// import MapListActive from "../../assets/images/map-list-active.svg";
import BankZoomIcon from '../../assets/images/bank-locations.svg';
import GeoLocZomIcon from '../../assets/images/zoom.svg';
import Polygon from "../../assets/images/polygon.svg";
import distance from "../../assets/images/distance.svg";
import Routing from "../../assets/images/maproute.svg";
import MarkerIcon from "../../assets/images/marker.svg";
import HdfcMarkerIcon from "../../assets/images/hdfc-marker.svg";

import { EditControl } from "react-leaflet-draw";
import "leaflet-draw/dist/leaflet.draw.css";

const MapView = ({ polygonData,showLoader,setShowLoader }) => {
  const [mapPolygon, setMapPolygon] = useState([]);
  const [markedBoundary, setMarkedBoundary] = useState([]);
  const [map, setMap] = useState();
  const [marker, setMarker] = useState([]);
  const [hdfcOffice, setHdfcOffice] = useState([]);
  const [hdfcOfficeValue, setHdfcOfficeValue] = useState([]);
  const [show, setShow] = useState(false);
  const [makingModeText, setMakingModeText] = useState("");
  const [directionValue, setDirectionValue] = useState([]);
  const [distanceKMeters, setDistanceKMeters] = useState("");
  const [routeDistanceKMeters, setRouteDistanceKMeters] = useState("");
  const [squareMeters, setSquareMeters] = useState("");
  const [squareFeet, setSquareFeet] = useState("");
  const [findRoute, setFindRoute] = useState(false);
  const [measureArea, setMeasureArea] = useState(false);
  const [polyLine, setPolyLine] = useState(false);
  const [showhdfcOfficeLocation,setShowhdfcOfficeLocation]=useState(false);
  

  useEffect(() => {
    if (polygonData !== undefined && map) {
      setTimeout(() => {
        getFeatures();
      }, 1500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [polygonData, map]);

  useEffect(() => {
    if (mapPolygon && mapPolygon.length !== 0) {
      if (map) {
        let { surveyNumberDetails } = polygonData;
        let latlng = surveyNumberDetails[0]?.centroid;
        if (latlng) {
          map.flyTo([latlng?.lat?.value, latlng?.lon?.value], 16, {
            duration: 1.5,
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapPolygon]);

  useEffect(() => {
    if (directionValue.length === 2) {
      // let arr = [...directionValue];
      // var from = turf.point([arr[0].lat, arr[0].lng]);
      // var to = turf.point([arr[1].lat, arr[1].lng]);
      // var distance = turf.distance(from, to, { units: "kilometers" });
      // setRouteDistanceKMeters(distance.toFixed(2));
      setShow(true);
      setDistanceKMeters("");
      setSquareMeters("");
      setSquareFeet("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [directionValue]);

  const polygonGetLoc = (ev) => {
    var direct = [];

    const latit = ev.lat;
    const long = ev.lng;

    var directObj = {
      lat: latit,
      lng: long,
    };

    direct = direct.concat(directObj);
    getDirection(direct);
  };

  const getDirection = (direct) => {
    if (directionValue.length !== 2) {
      var directionArr = directionValue.concat(direct);
      setDirectionValue(directionArr);
    }
  };

  const getFeatures = () => {
    if (polygonData !== undefined) {
      const { metadata, surveyNumberDetails, nearestOffices } = polygonData;
      if (metadata?.geometry) {
        setMarkedBoundary([
          {
            polygon: {
              type: "FeatureCollection",

              features: [
                {
                  //   datavalue: item,
                  type: "Feature",
                  properties: {
                    name: "undefined//undefined",
                  },
                  geometry: metadata?.geometry ? metadata?.geometry : null,
                  // centroid: item?.centroid ? item?.centroid : null,
                },
              ],
            },
          },
        ]);
      } else {
        let latlng = surveyNumberDetails[0]?.centroid;
        setMarker([latlng?.lat?.value, latlng?.lon?.value]);
      }
      let arr = [];
      surveyNumberDetails.forEach((item) => {
        arr.push({
          polygon: {
            type: "FeatureCollection",

            features: [
              {
                datavalue: item,
                type: "Feature",
                properties: {
                  name: 'undefined//undefined',
                },
                geometry: item?.geometry ? JSON.parse(item?.geometry) : null,
                centroid: item?.centroid ? item?.centroid : null,
              },
            ],
          },
        });
      });
      let officeMarker = nearestOffices.map((office) => {
        let { centroid } = office;
        return [centroid?.lat?.value, centroid?.lon?.value];
      });
      setHdfcOffice([...officeMarker]);

      // arr.push(poly);
      setMapPolygon([...arr]);
    }
  };

  const markerIcon = () => {
    return L.icon({
      iconUrl: MarkerIcon,
      iconSize: [25, 25],
    });
  };
  const hdfcIcon = () => {
    return L.icon({
      iconUrl: HdfcMarkerIcon,
      iconSize: [25, 25],
    });
  };

  const onEdit = (e) => { };

  const onCreate = (e) => {
    const { layerType, layer } = e;

    if (layerType === "polygon") {
      let arr = [];
      let latLng = layer.getLatLngs()[0];
      latLng.map((item) => {
        arr.push([item.lng, item.lat]);
        return null;
      });

      let polygon = {
        type: "FeatureCollection",
        features: [
          {
            datavalue: 1,
            type: "Feature",
            geometry: {
              type: "Polygon",
              coordinates: [arr],
            },
            centroid: "",
          },
        ],
      };
      let areaValue = turf.area(polygon) * 10.76391042;
      setShow(true);
      setDistanceKMeters("");
      setShow(true);
      setSquareMeters(turf.area(polygon).toFixed(2));
      setSquareFeet(areaValue.toFixed(2));
      setRouteDistanceKMeters("");
      setDistanceKMeters("");
    } else if (layerType === "polyline") {
      var tempLatLng = null;
      var totalDistance = 0.0;

      layer.getLatLngs().forEach(function (latlng) {
        if (tempLatLng == null) {
          tempLatLng = latlng;
          return;
        }
        totalDistance += tempLatLng.distanceTo(latlng);
        tempLatLng = latlng;
        return null;
      });
      let totalDistanceValue = (totalDistance / 1000).toFixed(3);
      setDistanceKMeters(totalDistanceValue);
      setShow(true);
      setSquareMeters("");
      setSquareFeet("");
      setRouteDistanceKMeters("");
    }
  };
  var hoverStyle = {
    color: "#D10000",
    weight: 3,
    opacity: 0.5,
    fillOpacity: 1,
    fillColor: "#e9060633"
  };
  var defaultStyle = {
    color: "#D10000",
    fillOpacity: 0,
    weight: 3,
    opacity: 0.5,
    dashArray: "7",
  }
  const viewOnEachCentroid = (data, layer) => {
    layer.on("mouseover", function (e) {
      layer.setStyle(hoverStyle);
    });
    layer.on("mouseout", function (e) { layer.setStyle(defaultStyle); });
    if (data.centroid !== undefined) {
      layer.bindTooltip(`
        <div class="popup-wrapper">
        <div class="heading">
        <h1>Centroid : </h1>
        <label>
        ${data.centroid.lat.value.toFixed(5)}N 
        ${data.centroid.lon.value.toFixed(5)}E
        </label>
      </div>
          <div class="popup-body">
          <h1>Survey Details:</h1>
          <ul>
         ${Object.keys(data?.datavalue?.surveyDetails).length !== 1 ? ` 
         <li>
          <label>Khasara</label>
          <label> ${data?.datavalue?.surveyDetails?.Khasara}</label>
         </li>
         <li>
         <label>Khewat</label>
         <label> ${data?.datavalue?.surveyDetails?.Khewat}</label>
        </li>
        <li>
        <label>Murabba</label>
        <label> ${data?.datavalue?.surveyDetails?.Murabba}</label>
       </li>
         `: ` <li>
          <label>Survey Number</label>
          <label> ${data?.datavalue?.surveyDetails[`Survey Number`]}</label>`}</li>   
          </ul>
        </div>
      </div>`
      )
    }
  }

  const onDeleted = () => { };
  const geoZoomLoc = () => {
    if (mapPolygon && mapPolygon.length !== 0) {
      if (map) {
        let { surveyNumberDetails } = polygonData;
        let latlng = surveyNumberDetails[0]?.centroid;
        if (latlng) {
          map.flyTo([latlng?.lat?.value, latlng?.lon?.value], 16, {
            duration: 1.5,
          });
        }
      }
    }
  };

  const hdfcOfficeData = async () => {
    try {
      setShowLoader(true);
      const { data, status } = await hdfcOfficeLocations();

      if (status === 200) {
        setShowLoader(false);
        setHdfcOfficeValue(data);
        map.flyTo([20.5937, 78.9629], 5, {
          duration: 1.5,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <>
    
      <MapContainer
        id="map"
        center={[20.593, 78.9629]}
        zoom={5}
        maxZoom={18}
        whenCreated={(map) => {
          setMap(map);
        }}
        loadingControl={true}
      >
        
        <ReactLeafletGoogleLayer
          apiKey={MAP_API_KEY}
          type={"hybrid"}
          useGoogMapsLoader={true}
        />
        {marker.length !== 0 && (
          <Marker position={marker} icon={markerIcon()}></Marker>
        )}
        {hdfcOffice &&
          hdfcOffice.length !== 0 &&
          hdfcOffice.map((mark) => {
            return <Marker position={mark} icon={hdfcIcon()} />;
          })}

        {hdfcOfficeValue.data &&
          hdfcOfficeValue.data.length !== 0 &&
          showhdfcOfficeLocation &&
          hdfcOfficeValue.data.map((mark) => {
            return <Marker position={[mark.Latitude, mark.Longitude]} icon={hdfcIcon()} />;
          })}
        {markedBoundary !== undefined && markedBoundary.length !== 0 && (
          <GeoJSON
            data={markedBoundary[0].polygon}
            style={() => ({
              color: "#FFF500",
              fillOpacity: 0.2,
              weight: 5,
              opacity: 0.7,
            })}
            onEachFeature={(cent, layer) => viewOnEachCentroid(cent, layer)}
          />
        )}
        {mapPolygon !== undefined &&
          mapPolygon.length !== 0 &&
          mapPolygon.map((item, index) => {
            return (
              <GeoJSON
                key={item.datavalue}
                data={item.polygon}
                style={() => ({
                  color: "#D10000",
                  fillOpacity: 0,
                  weight: 3,
                  opacity: 0.5,
                  dashArray: "7",
                })}
                onEachFeature={(cent, layer) => viewOnEachCentroid(cent, layer)}
              />
            );
          })}
        {findRoute && <OnClickEvent polygonGetLoc={polygonGetLoc} />}
        <Legend />

        {measureArea && (
          <FeatureGroup>
            <EditControl
              position="topleft"
              onEdited={onEdit}
              onCreated={onCreate}
              onDeleted={onDeleted}
              draw={{
                rectangle: false,
                circle: false,
                circlemarker: false,
                marker: false,
                polyline: false,
                polygon: true,
              }}
            />
          </FeatureGroup>
        )}
        {polyLine && (
          <FeatureGroup>
            <EditControl
              position="topleft"
              onEdited={onEdit}
              onCreated={onCreate}
              onDeleted={onDeleted}
              draw={{
                rectangle: false,
                circle: false,
                circlemarker: false,
                marker: false,
                polyline: true,
                polygon: false,
              }}
            />
          </FeatureGroup>
        )}


        {directionValue.map((data, key) => {
          return (
            <Marker
              position={data}
              zIndex={99999}
              fontSize={20}
              fontWeight="bold"
            />
          );
        })}
        {directionValue.length === 2 && (
          <RoutingMachine
            wayPoints={directionValue}
            setDistanceKMeters={setDistanceKMeters}
          />
        )}

        <MapPopup
          show={show}
          setShow={setShow}
          squareMeters={squareMeters}
          squareFeet={squareFeet}
          makingModeText={makingModeText}
          distanceKMeters={distanceKMeters}
          routeDistanceKMeters={routeDistanceKMeters}
          setSquareMeters={setSquareMeters}
          setSquareFeet={setSquareFeet}
          setDistanceKMeters={setDistanceKMeters}
          setRouteDistanceKMeters={setRouteDistanceKMeters}
          setDirectionValue={setDirectionValue}
        />
        <Pegman />
        <ScaleControl position="bottomleft" />
        <Control position="topleft">
          <button className="map-list-order" onClick={() => {
            geoZoomLoc();
          }}>
            <div className="map-icon">
              <img src={GeoLocZomIcon} alt="Map Icon" />
            </div>
          </button>
          <button className="map-list-order" onClick={() => { 
            setShowhdfcOfficeLocation(!showhdfcOfficeLocation)
            if(!showhdfcOfficeLocation)
            hdfcOfficeData(); }}>
            <div className="zoom-minus">
              <img src={BankZoomIcon} alt="Bank Location Icon" />
            </div>
          </button>
          <button className="map-list-order dropdown">
            <div
              data-bs-toggle="dropdown"
              aria-expanded="false"
              aria-haspopup="true" className="measu-poly-route">
              <img
                src={MapList}
                alt="map list icon"
              /></div>
            <ul className="dropdown-menu icon-order">

              <li
                onClick={() => {
                  setPolyLine(false);
                  // setMeasureList(false);
                  setMeasureArea(true);
                  setFindRoute(false);
                  setMakingModeText("Area of the Polygon");
                }}
              >
                <label>
                  <img src={Polygon} alt="map list icon" />
                </label>
                <span>Measure Area</span>
              </li>
              <li
                onClick={() => {
                  setMeasureArea(false);
                  // setMeasureList(!measureList);
                  setPolyLine(true);
                  setFindRoute(false);
                  setMakingModeText("Distance");
                }}
              >
                <label>
                  <img src={distance} alt="map list icon" />
                </label>
                <span>Measure Distance</span>
              </li>
              <li
                onClick={() => {
                  setFindRoute(true);
                  // setMeasureList(false);
                  setPolyLine(false);
                  setMeasureArea(false);
                  setMakingModeText("Route Distance");
                }}
              >
                <label>
                  <img src={Routing} alt="map list icon" />
                </label>
                <span>Measure Route</span>
              </li>
            </ul>
          </button>
        </Control>
      </MapContainer>
    </>
  );
};

export default MapView;
