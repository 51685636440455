import { postRequest } from "../utils/http-helper";
import { ANALYTICS_URL } from "../utils/url-helper";

const header = {
  "Content-Type": "application/json",
  accept: "application/json",
};

export const onAnalytics = async (payload) => {
  try {
    header["Authorization"] = JSON.parse(
      localStorage.getItem("user")
    ).accessToken;
    return await postRequest({
      url: ANALYTICS_URL,
      data: payload,
      noAuth: true,
      headers: header,
    });
  } catch (e) {
    return e;
  }
};
