import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import "./index.css";
import Close from "../../assets/images/cross.svg";
import CloseOPen from "../../assets/images/vector.svg";
import MapView from "../mapView/mapView";
import { onMapview } from "../../services/explorer-service";
import moment from "moment";
import loadingGIF from "../../assets/images/loadingGIF.gif"

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "25px",
    padding: "30px",
    paddingTop:"30px"
  },
};




const MapModalPopup = ({
  mapModalIsOpen,
  setIsMapOpen,
  mapViewData,
  setMapViewData,
}) => {
  const [openExpand, setOpenExpand] = useState(false);
  const [showLoader,setShowLoader]=useState(false);
  const [polygonData, setPolygonData] = useState();
  function closeModal() {
    setIsMapOpen(false);
    setMapViewData("");
    setOpenExpand(false);
  }

  useEffect(() => {
    setShowLoader(true);
    if (mapViewData !== "") {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapViewData]);

  const fetchData = async () => {
    try {
      
      const { data, status } = await onMapview(mapViewData?.requestId);

      if (status === 200) {
        setShowLoader(false);
        setPolygonData(data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Modal
        isOpen={mapModalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Example Modal"
      >
        <div className="map-modal">
          <div className="cross-icon" onClick={() => closeModal()}>
            <label>
              <img src={Close} alt="Close icon" />
            </label>
          </div>
          <div className="propeyeid-details">
            <div className="propeyeid-heading">
              <div className="propeyeid-value">
                <label>PROPEYE ID :</label>
                <label>{mapViewData?.propeyeId}</label>
              </div>
              <div className="close-opened">
                <div
                  className={
                    openExpand ? "close-opened-img active" : "close-opened-img"
                  }
                  onClick={() => setOpenExpand(!openExpand)}
                >
                  <img src={CloseOPen} alt="Close OPen" />
                </div>
              </div>
            </div>

            {openExpand ? (
              <div className="propeyeid-expand">
                <hr className="vertical-line"></hr>
                <ul className="propeyeid-content">
                  <li className="propeyeid-list">
                    <label className="propeyeid-title">
                      Property Marked On :
                    </label>
                    <span>
                      {moment(polygonData?.metaData?.reportDate).format(
                        "DD MMM YYYY"
                      )}
                    </span>
                  </li>
                  <li className="propeyeid-list">
                    <label>Property Category : </label>
                    <span>NA</span>
                  </li>
                  <li className="propeyeid-list">
                    <label>
                      Survey Details ({polygonData?.surveyNumberDetails.length}
                      ):
                    </label>
                    <ul className="survey-details-list">
                      {polygonData?.surveyNumberDetails.map((item) => {
                        return (
                          <>
                            {item?.surveyDetails["Survey Number"] ===
                            undefined ? (
                              <>
                                <li>
                                  <span>Khewat</span>
                                  <span>
                                    {item?.surveyDetails?.Khewat || "NA"}
                                  </span>
                                </li>
                                <li>
                                  <span>Murraba</span>
                                  <span>
                                    {item?.surveyDetails?.Murabba || "NA"}
                                  </span>
                                </li>
                                <li>
                                  <span>Khasara</span>
                                  <span>
                                    {item?.surveyDetails?.Khasara || "NA"}
                                  </span>
                                </li>
                              </>
                            ) : (
                              <>
                                <li>
                                  <span>Survey Number</span>
                                  <span>
                                    {item?.surveyDetails["Survey Number"] ||
                                      "NA"}
                                  </span>
                                </li>
                              </>
                            )}
                          </>
                        );
                      })}
                    </ul>
                  </li>
                </ul>
              </div>
            ) : null}
          </div>
          { showLoader && 
         <div className="loader_gif">
          <img src={loadingGIF} alt="loading.." height="30px" width="30px"/>
          </div>
          }
          

          {polygonData !== undefined && <MapView polygonData={polygonData} showLoader={showLoader} setShowLoader={setShowLoader} />}
        </div>
      </Modal>
    </>
  );
};

export default MapModalPopup;
