import { getRequest } from "../utils/http-helper"
import { REGION_URL} from "../utils/url-helper"



const header={
    "Content-Type": "application/json",
    accept: "application/json",
    
}



export const getStates=async()=>{
    // header["Authorization"] = accessToken;
    
    header["Authorization"]=JSON.parse(localStorage.getItem('user')).accessToken

    return await getRequest({
        url:`${REGION_URL}/27841bce-248f-4342-a78d-b6d817dceb24`,
        headers:header,
        noAuth:true
    })
}

export const getFilters =async(id)=>{
    header["Authorization"]=JSON.parse(localStorage.getItem('user')).accessToken

    return await getRequest({
        url:`${REGION_URL}/${id}`,
        headers:header,
        noAuth:true
    })
}