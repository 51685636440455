import React, { useEffect, useRef, useState } from "react";
import ExplorerTable from "../../components/explorerTable/explorerTable";

import "./explorer.css";

import Star from "../../assets/images/star.svg";
import Archive from "../../assets/images/archive.svg";

import "rsuite/dist/rsuite.min.css";

import { DateRangePicker } from "rsuite";

import Fuse from "fuse.js";

import moment from "moment";

import {
  getArchive,
  getExplorerData,
  getStarred,
  onArchiveRow,
  onStartedRow,
} from "../../services/explorer-service";

import Pagination from "../../components/pagination/pagination";

const styles = { width: "100%", display: "block", marginBottom: 10 };

const Explorer = ({ explorerTabs, apply, search }) => {
  const mountRef = useRef(false);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState("");
  const [dates, setDates] = useState([]);
  const [checked, setChecked] = useState({
    all: false,
    star: false,
    archive: false,
  });
  const [isSelected, setSelected] = useState(false);
  const [tableStarredData, setTableStarredData] = useState([]);
  const [tableArchiveData, setTableArchiveData] = useState([]);
  const [tableAllData, setAllTableData] = useState("");
  const [archiveAllData, setArchiveAllData] = useState([]);
  const [starAllData, setStarAllData] = useState([]);

  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageSize: 10,
    totalPage: 0,
  });

  const [starPagination, setStarPagination] = useState({
    currentPage: 1,
    pageSize: 10,
    totalPage: 0,
  });

  const [archivePagination, setArchivePagination] = useState({
    currentPage: 1,
    pageSize: 10,
    totalPage: 0,
  });

  // useEffect(() => {
  //   if (mountRef.current && dateRange && dateRange.length === 0) {
  //     let sliceData = getPaginationSlice(1, pagination.pageSize, tableAllData);
  //     setPagination({ ...pagination, currentPage: 1 });
  //     setTableData(sliceData);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [dateRange]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (mountRef.current) {
      dateFilter();
    } else {
      mountRef.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apply]);

  useEffect(() => {
    if (explorerTabs === "all") {
      const indexOfLastPost = pagination.currentPage * pagination.pageSize;
      const indexOfFirstPost = indexOfLastPost - pagination.pageSize;
      if (pagination.totalPage !== 0) {
        let res = tableAllData.slice(indexOfFirstPost, indexOfLastPost);
        setTableData(res);
      }
    } else if (explorerTabs === "star") {
      const indexOfLastPost =
        starPagination.currentPage * starPagination.pageSize;
      const indexOfFirstPost = indexOfLastPost - starPagination.pageSize;
      if (starPagination.totalPage !== 0) {
        let res = starAllData.slice(indexOfFirstPost, indexOfLastPost);
        setTableStarredData(res);
      }
    } else if (explorerTabs === "archive") {
      const indexOfLastPost =
        archivePagination.currentPage * archivePagination.pageSize;
      const indexOfFirstPost = indexOfLastPost - archivePagination.pageSize;
      if (archivePagination.totalPage !== 0) {
        let res = archiveAllData.slice(indexOfFirstPost, indexOfLastPost);
        setTableArchiveData(res);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pagination.currentPage,
    starPagination.currentPage,
    archivePagination.currentPage,
  ]);

  useEffect(() => {
    if (search !== "") {
      onSearch();
    } else {
      setTableData(tableAllData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const onSearch = () => {
    if (tableAllData.length !== 0) {
      const options = {
        keys: ["propeyeId", "region"],
        useExtendedSearch: true,
      };

      const fuse = new Fuse(tableAllData, options);
      const result = fuse.search(search);
      setTableData(
        result.map((item) => {
          return item.item;
        })
      );
    }
  };

  const fetchData = async () => {
    setLoading(true);
    const { data, status } = await getExplorerData();
    if (status === 200) {
      let res = [...data?.data];
      res &&
        res.forEach((item) => {
          item[item.requestId] = false;
        });

      getArchiveData(res);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const getArchiveData = async (response) => {
    const { data, status } = await getArchive();
    if (status === 200) {
      let archiveItem = [];

      response.map((item) => {
        data.map((archive) => {
          if (archive.requestId === item.requestId && archive?.archived) {
            archiveItem.push(item);
          }
          return null;
        });
        return null;
      });
      let notArchiveItem = response.filter(
        (item) =>
          !data.some(
            (archive) =>
              item.requestId === archive.requestId && archive?.archived
          )
      );

      setPagination({
        ...pagination,
        currentPage: 1,
        totalPage: notArchiveItem.length,
      });
      setTableData(
        notArchiveItem.slice(pagination.currentPage - 1, pagination.pageSize)
      );
      setAllTableData(notArchiveItem);

      let checkArhiveItem = getPaginationSlice(
        archivePagination.currentPage,
        archivePagination.pageSize,
        archiveItem
      );
      setArchiveAllData(archiveItem);

      setTableArchiveData(checkArhiveItem);

      setArchivePagination({
        ...archivePagination,
        currentPage: 1,
        totalPage: archiveItem.length,
      });
      getStartedData(notArchiveItem);
    }
  };

  const getStartedData = async (response) => {
    const { data, status } = await getStarred();
    if (status === 200) {
      let arr = [];
      data &&
        data.length !== 0 &&
        data.forEach((element) => {
          response.forEach((item) => {
            if (element.requestId === item.requestId && element?.starred) {
              arr.push(item);
            }
          });
        });

      let checkStarItem = getPaginationSlice(
        starPagination.currentPage,
        starPagination.pageSize,
        arr
      );

      setTableStarredData(checkStarItem);
      setStarAllData(arr);

      setStarPagination({
        ...starPagination,
        currentPage: 1,
        totalPage: arr.length,
      });
    }
  };

  const onCheckAll = () => {
    if (explorerTabs === "all") {
      let res = JSON.parse(JSON.stringify(tableAllData));
      res &&
        res.forEach((item) => {
          item[item.requestId] = !checked.all;
        });

      // checking unChecked rows
      let unCheckedItems = res.map((item) => !item[item.requestId]);
      setChecked({ ...checked, all: unCheckedItems.includes(false) });

      setSelected(unCheckedItems.includes(false));

      let checkData = getPaginationSlice(
        pagination.currentPage,
        pagination.pageSize,
        res
      );
      setTableData(checkData);

      setAllTableData(res);
    } else if (explorerTabs === "star") {
      let res = JSON.parse(JSON.stringify(tableStarredData));
      res &&
        res.forEach((item) => {
          item[item.requestId] = !checked.star;
        });

      // checking unChecked rows
      let unCheckedItems = res.map((item) => !item[item.requestId]);

      setSelected(unCheckedItems.includes(false));

      setChecked({ ...checked, star: unCheckedItems.includes(false) });
      let checkData = getPaginationSlice(
        starPagination.currentPage,
        starPagination.pageSize,
        res
      );
      setTableStarredData(checkData);
      setStarAllData(res);
    } else {
      let res = JSON.parse(JSON.stringify(archiveAllData));
      res &&
        res.forEach((item) => {
          item[item.requestId] = !checked.archive;
        });

      // checking unChecked rows
      let unCheckedItems = res.map((item) => !item[item.requestId]);

      setSelected(unCheckedItems.includes(false));

      setChecked({ ...checked, archive: unCheckedItems.includes(false) });

      let checkData = getPaginationSlice(
        archivePagination.currentPage,
        archivePagination.pageSize,
        res
      );
      setTableArchiveData(checkData);
      setArchiveAllData(res);
    }
  };

  const onChangeCheck = (e, value) => {
    let res = [...tableData];

    value[value.requestId] = !value[value.requestId];
    setTableData(res);
    unChecked();
  };

  const unChecked = () => {
    if (tableData && tableData.length !== 0) {
      let unCheckedItems = tableData.map((item) => !item[item.requestId]);
      let value = unCheckedItems.includes(true);
      setSelected(unCheckedItems.includes(false));
      setChecked(!value);
    } else {
      setChecked(false);
    }
  };

  const onAddToStar = async () => {
    let res = [...tableAllData];
    let selectedItem = res.filter((item) => {
      if (item[item.requestId]) {
        return item;
      }
      return null;
    });
    let payload = selectedItem.map((item) => {
      return {
        requestId: item.requestId,
        starred: "true",
      };
    });

    const { status } = await onStartedRow(payload);
    if (status === 200) {
      fetchData();
      selectedItem.forEach((item) => {
        item[item.requestId] = false;
      });
      setSelected(false);
    }
  };

  const onAddToArchive = async () => {
    let res = [...tableAllData];
    let selectedItem = [];
    // let notSelectedItem = [];
    res.filter((item) => {
      if (item[item.requestId]) {
        selectedItem.push(item);
      }
      // else {
      //   notSelectedItem.push(item);
      // }
      return null;
    });
    let payload = selectedItem.map((item) => {
      return {
        requestId: item.requestId,
        archived: "true",
      };
    });

    const { status } = await onArchiveRow(payload);
    if (status === 200) {
      fetchData();
      selectedItem.forEach((item) => {
        item[item.requestId] = false;
      });
      //setTableArchiveData(selectedItem);
      //setTableData(notSelectedItem);
      setSelected(false);
      setChecked({ ...checked, all: false });
    }
  };

  const dateFilter = (dateRange) => {
    if (dateRange && dateRange.length !== 0) {
      let dateData = tableAllData.filter((item) => {
        let dateFormat = moment(
          item?.requestCreationDate,
          "DD/MM/YYYY hh:mm:ss"
        );
        let new_date = new Date(
          new Date(dateFormat).toLocaleDateString("en-US")
        );
        return (
          new_date.getTime() >=
            new Date(dateRange[0].toLocaleDateString("en-US")).getTime() &&
          new_date.getTime() <=
            new Date(dateRange[1].toLocaleDateString("en-US")).getTime()
        );

        // return (
        //   new Date(new Date(new_date).toLocaleDateString("en-US")).getTime() >=
        //     new Date(dateRange[0].toLocaleDateString("en-US")).getTime() &&
        //   new Date(new_date).getTime() <=
        //     new Date(dateRange[1].toLocaleDateString("en-US")).getTime()
        // );
      });
      // let filterData = getPaginationSlice(1, pagination.pageSize, dateData);
      // console.log(filterData);
      setTableData(dateData);
    }
  };

  const getPaginationSlice = (page, pageSize, data) => {
    const indexOfLastPost = page * pageSize;
    const indexOfFirstPost = indexOfLastPost - pageSize;
    return data.slice(indexOfFirstPost, indexOfLastPost);
  };

  const onClear = () => {
    let sliceData = getPaginationSlice(1, pagination.pageSize, tableAllData);
    setPagination({ ...pagination, currentPage: 1 });
    setTableData(sliceData);
  };

  return (
    <div className="explorer-container">
      <div className="table-wrapper">
        <div className="table-top-content">
          {isSelected && explorerTabs === "all" && (
            <>
              <div className="table-top-items" onClick={() => onAddToStar()}>
                <label>
                  <img src={Star} alt="star icon" />
                </label>
                <span>Add to Star</span>
              </div>
              <div className="table-top-items" onClick={() => onAddToArchive()}>
                <label>
                  <img src={Archive} alt="star icon" />
                </label>
                <span>Add to Archive</span>
              </div>
            </>
          )}
          <div className="date-range-picker">
            <DateRangePicker
              placeholder="Select Date"
              showOneCalendar
              style={styles}
              format="yyyy/MM/dd"
              placement="auto"
              // value={date}
              onClean={() => {
                onClear();
                setDates([]);
              }}
              onChange={(e) => {
                dateFilter(e);
                setDates(e);
              }}
            />
          </div>
        </div>
        <table className="table">
          <thead>
            <tr>
              <td className="first-td width-10 ">
                {explorerTabs !== "archive" && (
                  <label className="checkBox">
                    <input
                      type="checkbox"
                      className="check"
                      checked={
                        checked.all && explorerTabs === "all"
                          ? checked.all
                          : checked.star && explorerTabs === "star"
                          ? checked.star
                          : checked.archive && explorerTabs === "archive"
                          ? checked.archive
                          : false
                      }
                      onChange={() => onCheckAll()}
                    ></input>
                  </label>
                )}
                <span>SELECT</span>
              </td>
              <td className="width-15">PROPEYE ID</td>
              <td className="width-15">ADDED ON</td>
              {/* <td>STATE</td>
              <td>DISTRICT</td>
              <td>TEHSIL</td>
              <td>VILLAGE</td> */}
              <td className="width-20">REGION</td>
              <td className="width-15">PROPEYE REPORT</td>
              <td className="width-15">OWNERSHIP REPORT</td>
              <td className="width-10">MORE</td>
            </tr>
          </thead>
          <tbody>
            {explorerTabs === "all" && tableData && tableData.length !== 0 ? (
              tableData.map((item, index) => {
                return (
                  <ExplorerTable
                    keyvalue={index}
                    item={item}
                    setChecked={setChecked}
                    onChangeCheck={onChangeCheck}
                    fetchData={fetchData}
                    starAllData={starAllData}
                    tabs={explorerTabs}
                  />
                );
              })
            ) : explorerTabs === "all" && loading ? (
              <tr>
                <div>Loading...</div>
              </tr>
            ) : (
              explorerTabs === "all" &&
              tableData !== "" && (
                <tr>
                  <div>No Data Available</div>
                </tr>
              )
            )}
            {explorerTabs === "star" &&
              tableStarredData.length !== 0 &&
              tableStarredData.map((item, index) => {
                return (
                  <ExplorerTable
                    keyvalue={index}
                    item={item}
                    fetchData={fetchData}
                    onChangeCheck={onChangeCheck}
                    tabs={explorerTabs}
                  />
                );
              })}
            {explorerTabs === "archive" &&
              tableArchiveData.length !== 0 &&
              tableArchiveData.map((item, index) => {
                return (
                  <ExplorerTable
                    keyvalue={index}
                    explorerTabs
                    item={item}
                    fetchData={fetchData}
                    onChangeCheck={onChangeCheck}
                    tabs={explorerTabs}
                  />
                );
              })}
          </tbody>
        </table>
        <div className="pagination-wrapper">
          {search === "" && dates.length === 0 && (
            <Pagination
              data={tableData}
              pagination={pagination}
              setPagination={setPagination}
              explorerTabs={explorerTabs}
              starPagination={starPagination}
              setStarPagination={setStarPagination}
              archivePagination={archivePagination}
              setArchivePagination={setArchivePagination}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Explorer;
