import L from "leaflet";
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";

import "leaflet-routing-machine";
import { useMap } from "react-leaflet";
import { useEffect } from "react";

export default function RoutingMachine({ wayPoints, setDistanceKMeters }) {
  const map = useMap();

  useEffect(() => {
    if (!map) return;

    const instance = L.Routing.control({
      waypoints: [
        [wayPoints[0]?.lat, wayPoints[0]?.lng],
        [wayPoints[1]?.lat, wayPoints[1]?.lng],
      ],
      // lineOptions: {
      //   styles: [{ color: "red" }],
      // },
      fitSelectedRoutes: true,

      show: false,
      // showAlternatives: true,
      routeWhileDragging: false,
      // draggableWaypoints: true,
    }).addTo(map);
    instance.on("routesfound", (e) => {
      var routes = e.routes;
      let { summary } = routes[0];
      let distance = summary.totalDistance / 1000;
      setDistanceKMeters(distance.toFixed(3));
    });
    return () => map.removeControl(instance);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  return null;
}
