import { getRequest } from "../utils/http-helper"
import { REGION_URL } from "../utils/url-helper"


const header={
    "Content-Type": "application/json",
    accept: "application/json",
    
}

export const getMapPolygon=async(id)=>{
    header["Authorization"]=JSON.parse(localStorage.getItem('user')).accessToken

    return await getRequest({
        url:`${REGION_URL}/${id}/boundary`,
        headers:header,
        noAuth:true
    })
}