import React, { useEffect, useState } from "react";

import Sidebar from "../../components/sidebar/sidebar";

import Map from "../../components/map/map";
import Notifications from "../../components/notifications";

import "./dashboard.css";

import Profile from "../../assets/images/profile.svg";
import Notification from "../../assets/images/notification.svg";
import Logout from "../../assets/images/logout.svg";
import Explorer from "../explorer/explorer";
import Analytics from "../analytics/analytics";
import { useHistory } from "react-router-dom";

const Dashboard = () => {
  const history = useHistory();

  const [tabs, setTabs] = useState("locator");
  const [menuOpen, setMenuOpen] = useState(false);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [polygonId, setPolygonId] = useState("");
  const [explorerTabs, setExplorerTabs] = useState("all");
  const [dateRange, setDateRange] = useState();
  const [apply, setApply] = useState(false);
  const [search, setSearch] = useState("");
  const [postData, setPostData] = useState({});

  useEffect(() => {
    setPolygonId("");
    setPostData({});
  }, [tabs]);

  const getSearchBoundary = (id) => {
    setPolygonId(id);
  };

  const onLogout = () => {
    localStorage.clear();
    history.push("/login");
  };

  return (
    <>
      <div className="dashboard-container">
        <div className="sidebar-wrapper">
          <Sidebar
            tabs={tabs}
            getSearchBoundary={getSearchBoundary}
            setExplorerTabs={setExplorerTabs}
            explorerTabs={explorerTabs}
            setDateRange={setDateRange}
            setApply={setApply}
            search={search}
            setSearch={setSearch}
            setPostData={setPostData}
          />
        </div>
        <div className="dashboard-wrapper">
          <div className="navbar-container">
            <div className="list-wrapper">
              <ul className="left-list">
                <li
                  className={tabs === "locator" ? "active" : ""}
                  onClick={() => setTabs("locator")}
                >
                  LOCATOR
                </li>
                <li
                  className={tabs === "explorer" ? "active" : ""}
                  onClick={() => setTabs("explorer")}
                >
                  EXPLORER
                </li>
                <li
                  className={tabs === "analytics" ? "active" : ""}
                  onClick={() => setTabs("analytics")}
                >
                  ANALYTICS
                </li>
              </ul>
              <ul className="right-list">
                {/* Notifications */}
                {/* <li className="notification">
                  <div
                    onClick={() => setNotificationOpen(!notificationOpen)}
                    className="notifications-section"
                  >
                    <label>
                      <img src={Notification} alt="Notification" />
                    </label>
                    <span className="green-dot"></span>
                  </div>
                  <Notifications notificationOpen={notificationOpen} />
                </li> */}
                <li className="profile" onClick={() => setMenuOpen(!menuOpen)}>
                  <img src={Profile} alt="profile" />
                </li>
              </ul>

              <div
                className={
                  menuOpen ? "sub-menu-wrapper show" : "sub-menu-wrapper"
                }
              >
                <div className="sub-menu">
                  <div className="menu-items">
                    <div to="/login" onClick={() => onLogout()}>
                      <img src={Logout} alt="logout" />
                      <span>Logout</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {tabs === "locator" && (
            <div className="map-wrapper">
              <Map polygonId={polygonId} />
            </div>
          )}
          {tabs === "explorer" && (
            <div className="explorer-wrapper">
              <Explorer
                explorerTabs={explorerTabs}
                dateRange={dateRange}
                apply={apply}
                search={search}
              />
            </div>
          )}
          {tabs === "analytics" && (
            <div className="anayltics-wrapper">
              <Analytics postData={postData} setPostData={setPostData} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
