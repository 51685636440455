import React, { useEffect, useState, useRef } from "react";
import Select from "react-select";

import { PieChart, pieChartDefaultProps } from "react-minimal-pie-chart";

import "./analytics.css";

import { onAnalytics } from "../../services/analytics-service";

const option = [
  {
    label: "Last 1 Month",
    value: "month",
  },
  {
    label: "Today",
    value: "today",
  },
  {
    label: "Last Week",
    value: "week",
  },
];

const Analytics = ({ postData, setPostData }) => {
  const [analyticsData, setAnalayticsData] = useState({});
  const mountRef = useRef(false);
  useEffect(() => {
    if (mountRef.current) {
      fetchData(postData);
    } else {
      mountRef.current = true;
    }
    // if (Object.keys(postData).length !== 0) {

    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postData]);

  const fetchData = async (payload) => {
    try {
      const { data, status } = await onAnalytics(payload);
      if (status === 200) {
        setAnalayticsData(data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onChangeDate = (type) => {
    // if (Object.keys(postData).length !== 0) {
    let obj = postData;
    obj["date"] = type.value;

    fetchData(obj);
    // }
  };

  return (
    <div className="anlaytics-container">
      <div className="date-selector">
        <Select
          classNamePrefix="react-select"
          options={option}
          // isDisabled={Object.keys(postData).length !== 0 ? false : true}
          onChange={(e) => onChangeDate(e)}
        ></Select>
      </div>
      <hr className="horizontal-line" />
      <div className="chart-wrapper">
        <div className="propeye-report">
          <div className="report-heading">
            <h1>PropEYE Report </h1>
          </div>
          <div className="propeye-wrapper">
            <PieChart
              data={[
                {
                  title: analyticsData?.propertyReport?.totalrequest,
                  value: analyticsData?.propertyReport?.totalrequest,
                  color: "#2F80ED",
                }, //BLUE
                {
                  title: analyticsData?.propertyReport?.totalDelayed,
                  value: analyticsData?.propertyReport?.totalDelayed,
                  color: "#FF8A00",
                }, //YELLOW
                {
                  title: analyticsData?.propertyReport?.totalError,
                  value: analyticsData?.propertyReport?.totalError,
                  color: "#D10000",
                }, //RED
                {
                  title: analyticsData?.propertyReport?.generatedCount,
                  value: analyticsData?.propertyReport?.generatedCount,
                  color: "#219653",
                }, //GREEN
              ]}
              animate
              lineWidth={35}
              radius={pieChartDefaultProps.radius - 9}
              // labelPosition={100 - 30 / 2}
              segmentsShift={0.8}
              background="#e5e5e5"
            ></PieChart>
          </div>
          <hr className="horizontal-line" />

          <div className="statistic-report">
            <div className="cards">
              <div className="card-items">
                <label>Total Reports Generated</label>
                <div className="sub-data">
                  <label className="green"></label>
                  <span htmlFor="">
                    {analyticsData?.propertyReport?.generatedCount}
                  </span>
                </div>
              </div>
            </div>
            <div className="cards">
              <div className="card-items">
                <label>Total Reports Requested</label>
                <div className="sub-data">
                  <label className="blue"></label>
                  <span>{analyticsData?.propertyReport?.totalrequest}</span>
                </div>
              </div>
            </div>
            <div className="cards">
              <div className="card-items">
                <label>Total Reports Delayed</label>
                <div className="sub-data">
                  <label className="yellow"></label>
                  <span>{analyticsData?.propertyReport?.totalDelayed}</span>
                </div>
              </div>
            </div>
            <div className="cards">
              <div className="card-items">
                <label>Total Reports Errors</label>
                <div className="sub-data">
                  <label className="red"></label>
                  <span>{analyticsData?.propertyReport?.totalError}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="ownership-report">
          <div className="report-heading">
            <h1>Ownership Report </h1>
          </div>
          <div className="propeye-wrapper">
            <PieChart
              data={[
                {
                  title: analyticsData?.ownershipReport?.totalrequest,
                  value: analyticsData?.ownershipReport?.totalrequest,
                  color: "#2F80ED",
                }, //BLUE
                {
                  title: analyticsData?.ownershipReport?.totalDelayed,
                  value: analyticsData?.ownershipReport?.totalDelayed,
                  color: "#FF8A00",
                }, //YELLOW
                {
                  title: analyticsData?.ownershipReport?.totalError,
                  value: analyticsData?.ownershipReport?.totalError,
                  color: "#D10000",
                }, //RED
                {
                  title: analyticsData?.ownershipReport?.generatedCount,
                  value: analyticsData?.ownershipReport?.generatedCount,
                  color: "#219653",
                }, //GREEN
              ]}
              animate
              lineWidth={35}
              radius={pieChartDefaultProps.radius - 9}
              // labelPosition={100 - 30 / 2}
              background="#e5e5e5"
              segmentsShift={0.8}
            ></PieChart>
          </div>
          <hr className="horizontal-line" />
          <div className="statistic-report">
            <div className="cards">
              <div className="card-items">
                <label>Total Reports Generated</label>
                <div className="sub-data">
                  <label className="green"></label>
                  <span htmlFor="">
                    {analyticsData?.ownershipReport?.generatedCount}
                  </span>
                </div>
              </div>
            </div>
            <div className="cards">
              <div className="card-items">
                <label>Total Reports Requested</label>
                <div className="sub-data">
                  <label className="blue"></label>
                  <span>{analyticsData?.ownershipReport?.totalrequest}</span>
                </div>
              </div>
            </div>
            <div className="cards">
              <div className="card-items">
                <label>Total Reports Delayed</label>
                <div className="sub-data">
                  <label className="yellow"></label>
                  <span>{analyticsData?.ownershipReport?.totalDelayed}</span>
                </div>
              </div>
            </div>
            <div className="cards">
              <div className="card-items">
                <label>Total Reports Errors</label>
                <div className="sub-data">
                  <label className="red"></label>
                  <span>{analyticsData?.ownershipReport?.totalError}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Analytics;
