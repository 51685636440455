import { getRequest, postRequest } from "../utils/http-helper";
import { LOGIN_URL, REFRESH_TOKEN_URL, RSA_KEY_URL } from "../utils/url-helper";

export const getRsaKey = async () => {
  try {
    return await getRequest({
      url: RSA_KEY_URL,
    });
  } catch (e) {
    return e;
  }
};

export const getLogin = async (payload) => {
  try {
    return await postRequest({
      url: LOGIN_URL,
      data: payload,
    });
  } catch (e) {
    return e;
  }
};

export const getRefreshToken = async () => {
  try {
    return await postRequest({
      url: REFRESH_TOKEN_URL,
    });
  } catch (error) {
    return error;
  }
};
