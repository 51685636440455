import React from "react";

import "./mapPopup.css";

import Cross from "../../assets/images/cross.svg";
const MapPopup = ({ show, setShow, setDirectionValue, squareMeters, squareFeet, makingModeText, distanceKMeters, routeDistanceKMeters, setSquareMeters, setSquareFeet, setDistanceKMeters, setRouteDistanceKMeters }) => {
  const onClose = () => {
    setShow(!show)
    setSquareMeters("")
    setSquareFeet("")
    setDistanceKMeters("");
    setRouteDistanceKMeters("");
    setDirectionValue([])

  }
  return (
    <>
      {show ? (
        <div className="modal-container">
          <div className="headings">
            <label>Marking Mode</label>
            <img src={Cross} alt="cross icon" onClick={() => onClose()} />
          </div>
          <hr />
          <div className="sub-heading">
            <label>{makingModeText}</label>
            <div className="sub-heading-item">
              <div className="mete-feet">
                {squareMeters !== "" ? (
                  <>
                    <label>{squareMeters} sq m </label> |
                    <span> {squareFeet} sq feet</span>

                  </>) : null}
                {distanceKMeters !== "" ? (<><label>{distanceKMeters} Km </label></>) : null}
                {routeDistanceKMeters !== "" ? (<label>{routeDistanceKMeters} Km </label>) : null}
              </div>
            </div>
          </div>
        </div>
      ) : null
      }
    </>
  );
};

export default MapPopup;
