import React, { useEffect, useState } from "react";
import Select from "react-select";

import "./selectfilter.css";

import { getStates, getFilters } from "../../services/filter-api-services";
import SelectPicker from "./selectpicker.jsx";

const customStyles = {
  option: (styles, { isDisabled }) => {
    return {
      ...styles,
      color: isDisabled ? "#c4c4c4" : undefined,
      cursor: isDisabled ? "not-allowed" : "default",
    };
  },
};

const SelectFilter = ({ getSearchBoundary }) => {
  const [stateOption, setStateOption] = useState([]);
  const [filters, setFilters] = useState([]);
  const [disable, setDisable] = useState(true);
  const [boundaryId, setBoundaryId] = useState("");
  const [stateValue, setStateValue] = useState(null);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const { data, status } = await getStates();
    if (status === 200) {
      const { regions } = data?.data;
      // let arrFilter = regions.filter((item) => {
      //   return {
      //     label: item.name,
      //     value: item.id,
      //   };
      //   return null;
      // });
      let arr = regions.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });

      setStateOption([...arr]);
    }
  };

  const onChangeHandler = (e, filter = null) => {
    if (filter === null || filter.nextLevelAccess) {
      apiCall(e.value, filter);
      if (filter !== null && filter?.currentLevelAccess) {
        setBoundaryId(e.value);
        setDisable(false);
      } else {
        setDisable(true);
      }
    } else {
      setDisable(false);
      setBoundaryId(e.value);
    }
  };

  const apiCall = async (id, filterData) => {
    const { data, status } = await getFilters(id);

    var fil = [...filters];

    if (
      data?.data !== null &&
      fil.some((e) => e.currentLevel === data?.data.currentLevel)
    ) {
      let index = fil.findIndex(
        (x) => x.currentLevel === data.data.currentLevel
      );
      let new_slice_arr = fil.slice(0, index);
      setFilters([...new_slice_arr, data.data]);

      // if (data?.data?.nextLevelAccess) {
      //   setDisable(true);
      // }
    } else if (status === 200 && data?.data !== null) {
      fil.push(data.data);
      setFilters([...fil]);
    } else {
      let index = fil.findIndex(
        (x) => x.currentLevel === filterData.currentLevel
      );
      let new_slice_arr = fil.slice(0, index + 1);
      setFilters([...new_slice_arr]);
      setDisable(false);
    }
  };

  const getBoundary = () => {
    getSearchBoundary(boundaryId);
  };

  return (
    <>
      <div className="select-group">
        <div className="filter-headings">
          <label>FILTERS</label>
          <span
            onClick={() => {
              setFilters([]);
              setDisable(true);
              setStateValue(null);
            }}
          >
            Clear All
          </span>
        </div>
        <div className="filter-select">
          <div className="filter-group">
            <label>{"State*"}</label>
            <Select
              placeholder="State*"
              classNamePrefix="react-select"
              options={stateOption}
              value={stateValue}
              styles={customStyles}
              onChange={(e) => {
                setStateValue(e);
                onChangeHandler(e);
              }}
            ></Select>
          </div>
          {filters.length !== 0 &&
            filters.map((filter, index) => {
              return (
                <div className="filter-group" key={index}>
                  <SelectPicker
                    keyvalue={index}
                    name={filter.currentLevel}
                    filter={filter}
                    currentLevel={filter.currentLevel}
                    onChangeHandler={onChangeHandler}
                    regions={filter.regions}
                  ></SelectPicker>
                </div>
              );
            })}

          <div>
            <button
              className="btn-search"
              disabled={disable}
              onClick={() => getBoundary()}
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectFilter;
