export const BASE_URL = process.env.REACT_APP_BASE_URL;


export const MAP_API_KEY = "AIzaSyD8wryjmBuy_6fmhE0jWiOrIrAKBVv9i60";

export const AUTH_BASE_URL = `${BASE_URL}/v1/auth`;

export const LOGIN_URL = `${AUTH_BASE_URL}/login`;

export const RSA_KEY_URL = `${AUTH_BASE_URL}/rsa/`;

export const REGION_URL = `${BASE_URL}/v1/region`;

export const REFRESH_TOKEN_URL = `${AUTH_BASE_URL}/refresh-both-tokens`;

export const EXPLORER_URL = `${BASE_URL}/v1/report/user-requests`;

export const TABLE_POST_URL = `${BASE_URL}/v1/report`;

export const DOWNLOAD_URL = `${BASE_URL}/v1/report`;

export const MAP_VIEW_URL = `${BASE_URL}/v1/report`;

export const ANALYTICS_URL = `${BASE_URL}/v1/report/analytics`;

export const REGENERATE_URL = `${BASE_URL}/v1/report`;

export const HDFC_OFFICE_URL = `${BASE_URL}/v1/report/hdfc-offices`;
