import { getRequest, postRequest } from "../utils/http-helper";
import {
  DOWNLOAD_URL,
  EXPLORER_URL,
  MAP_VIEW_URL,
  REGENERATE_URL,
  TABLE_POST_URL,
  HDFC_OFFICE_URL,
} from "../utils/url-helper";

const header = {
  "Content-Type": "application/json",
  accept: "application/json",
};

export const getExplorerData = async () => {
  header["Authorization"] = JSON.parse(
    localStorage.getItem("user")
  ).accessToken;
  //let userId = "01ce534c-9c99-4c80-a7a6-bd4d600937bb"; //JSON.parse(localStorage.getItem("user"))?.userId;
  try {
    return await getRequest({
      url: EXPLORER_URL,
      headers: header,
      noAuth: true,
    });
  } catch (e) {
    return e;
  }
};

export const onStartedRow = async (payload) => {
  header["Authorization"] = JSON.parse(
    localStorage.getItem("user")
  ).accessToken;
  // let userId = JSON.parse(localStorage.getItem("user"))?.userId;

  let URL = `${TABLE_POST_URL}/set-starred-status`;

  try {
    return await postRequest({
      url: URL,
      headers: header,
      noAuth: true,
      data: payload,
    });
  } catch (e) {
    return e;
  }
};

export const onArchiveRow = async (payload) => {
  header["Authorization"] = JSON.parse(
    localStorage.getItem("user")
  ).accessToken;
  //let userId = JSON.parse(localStorage.getItem("user"))?.userId;

  let URL = `${TABLE_POST_URL}/set-archive-status`;

  try {
    return await postRequest({
      url: URL,
      headers: header,
      noAuth: true,
      data: payload,
    });
  } catch (e) {
    return e;
  }
};

export const getStarred = async () => {
  try {
    header["Authorization"] = JSON.parse(
      localStorage.getItem("user")
    ).accessToken;
    let URL = `${TABLE_POST_URL}/set-starred-status`;
    return await getRequest({
      url: URL,
      noAuth: true,
      headers: header,
    });
  } catch (e) {
    return e;
  }
};

export const getArchive = async () => {
  try {
    header["Authorization"] = JSON.parse(
      localStorage.getItem("user")
    ).accessToken;
    let URL = `${TABLE_POST_URL}/set-archive-status`;
    return await getRequest({
      url: URL,
      noAuth: true,
      headers: header,
    });
  } catch (e) {
    return e;
  }
};

export const onDownloadReport = async (requestId) => {
  header["Authorization"] = JSON.parse(
    localStorage.getItem("user")
  ).accessToken;

  let URL = `${DOWNLOAD_URL}/${requestId}/property-report`;

  try {
    return await getRequest({
      url: URL,
      headers: header,
      noAuth: true,
    });
  } catch (e) {
    return e;
  }
};

export const onDownloadOwnershipReport = async (requestId) => {
  header["Authorization"] = JSON.parse(
    localStorage.getItem("user")
  ).accessToken;

  let URL = `${DOWNLOAD_URL}/${requestId}/ownership-report`;

  try {
    return await getRequest({
      url: URL,
      headers: header,
      noAuth: true,
    });
  } catch (e) {
    return e;
  }
};

export const onMapview = async (requestId) => {
  try {
    header["Authorization"] = JSON.parse(
      localStorage.getItem("user")
    ).accessToken;

    let URL = `${MAP_VIEW_URL}/${requestId}/map-view`;
    return await getRequest({
      url: URL,
      headers: header,
      noAuth: true,
    });
  } catch (e) {
    return e;
  }
};

export const onRegenerateReport = async (requestId) => {
  try {
    header["Authorization"] = JSON.parse(
      localStorage.getItem("user")
    ).accessToken;
    let URL = `${REGENERATE_URL}/${requestId}/regenerate-report`;
    return await getRequest({
      url: URL,
      headers: header,
      noAuth: true,
    });
  } catch (e) {
    return e;
  }
};

export const hdfcOfficeLocations = async (requestId) => {
  try {
    header["Authorization"] = JSON.parse(
      localStorage.getItem("user")
    ).accessToken;
    let URL = `${HDFC_OFFICE_URL}`;
    return await getRequest({
      url: URL,
      headers: header,
      noAuth: true,
    });
  } catch (e) {
    return e;
  }

};
