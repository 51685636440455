import React from "react";
import Select from "react-select";

const customStyles = {
  option: (styles, { isDisabled }) => {
    return {
      ...styles,
      color: isDisabled ? "#c4c4c4" : undefined,
      cursor: isDisabled ? "not-allowed" : "default",
    };
  },
};

const SelectPicker = ({
  currentLevel,
  onChangeHandler,
  regions,
  filter,

  keyvalue,
}) => {
  // const [selectValue, setSelectValue] = useState(value);

  const getOptions = (arr) => {
    return arr.map((item) => {
      return {
        label: item.name,
        value: item.id,
        has_geometry: item?.has_geometry,
      };
    });
  };

  return (
    <>
      <label>{currentLevel + "*"}</label>
      <Select
        key={keyvalue}
        placeholder={currentLevel + "*"}
        classNamePrefix="react-select"
        options={getOptions(regions)}
        menuPlacement={!filter.nextLevelAccess ? "top" : "bottom"}
        isOptionDisabled={(option) => {
          if (filter.currentLevel === "Village") {
            return !option.has_geometry;
          } else if (filter.currentLevel === "Khewat") {
            return !option.has_geometry;
          } else if (filter.nextLevelAccess === false) {
            return !option.has_geometry;
          }
        }}
        styles={customStyles}
        onChange={(e) => {
          onChangeHandler(e, filter);

          // setSelectValue(e);
        }}
      ></Select>
    </>
  );
};

export default SelectPicker;
