import React, { useState } from "react";

import "./explorerTable.css";

//import RaiseQuery from "../../assets/images/raise-querry.svg";
import Request from "../../assets/images/table/request.svg";
import Download from "../../assets/images/table/download.svg";
import Process from "../../assets/images/table/process.svg";
import Failed from "../../assets/images/table/failed.svg";
import StarActive from "../../assets/images/star-active.svg";
import OutlineStar from "../../assets/images/outline-star.svg";
import Archive from "../../assets/images/archive.svg";
import MapView from "../../assets/images/map-view.svg";
import UnArchive from "../../assets/images/unarchive.svg";
import Delay from "../../assets/images/table/delay.svg";
import NotAvailable from "../../assets/images/table/not-avaliable.svg";
import ReportRegenerate from "../../assets/images/pencil-edit.svg";

import ModalPopup from "../modal/modal";
import MapModalPopup from "../mapmodal/";

import moment from "moment";

import {
  onArchiveRow,
  onDownloadOwnershipReport,
  onDownloadReport,
  onRegenerateReport,
  onStartedRow,
} from "../../services/explorer-service";

const ExplorerTable = ({
  keyvalue,
  explorerTabs,
  item,
  onChangeCheck,
  fetchData,
  starAllData,
  tabs,
}) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [mapModalIsOpen, setIsMapOpen] = useState(false);
  const [mapViewData, setMapViewData] = useState("");
  const onChange = (e, item) => {
    onChangeCheck(e, item);
  };

  const onHandleStar = async (value, type) => {
    const { status } = await onStartedRow([
      {
        requestId: value?.requestId,
        starred: !type,
      },
    ]);
    if (status === 200) {
      fetchData();
    }
  };

  const onHandleArchive = async (value, type) => {
    const { status } = await onArchiveRow([
      {
        requestId: value?.requestId,
        archived: type === "archive" ? true : false,
      },
    ]);
    if (status === 200) {
      fetchData();
    }
  };

  const downloadReport = async (item) => {
    if (item?.reportStatus === 1) {
      const { data, status } = await onDownloadReport(item?.requestId);
      if (status === 200) {
        if (data?.data[0]?.reportLink) var link = document.createElement("a");
        link.href = data?.data[0]?.reportLink;
        // link.download = "file.pdf";
        link.dispatchEvent(new MouseEvent("click"));
      }
    }
  };

  const downloadOwnershipReport = async (item) => {
    if (item?.reportStatus === 1) {
      const { data, status } = await onDownloadOwnershipReport(item?.requestId);
      if (status === 200) {
        if (data?.data[0]?.reportLink) var link = document.createElement("a");
        link.href = data?.data[0]?.reportLink;
        // link.download = "file.pdf";
        link.dispatchEvent(new MouseEvent("click"));
      }
    }
  };

  const getToolTip = () => {
    let color =
      item?.reportStatus === 0
        ? "blue"
        : item?.reportStatus === 1
        ? "green"
        : item?.reportStatus === -2
        ? "red"
        : "yellow";
    let text =
      item?.reportStatus === 0
        ? "Requested"
        : item?.reportStatus === 1
        ? "Generated"
        : item?.reportStatus === -2
        ? "Error"
        : "In Progress";

    return (
      <>
        <label
          onClick={() => downloadReport(item)}
          style={{ cursor: item?.reportStatus === 1 ? " pointer" : "default" }}
        >
          <img
            src={
              item?.reportStatus === 0
                ? Request
                : item?.reportStatus === 1
                ? Download
                : item?.reportStatus === -2
                ? Failed
                : item?.reportStatus === -1
                ? Process
                : Delay
            }
            alt="requeset icon"
          />
        </label>
        <div className="report-text-container ">
          <div className={`report-text ${color}`}>
            <label>{text}</label>
          </div>
        </div>
      </>
    );
  };

  const getOwnershipToolTip = () => {
    let color =
      item?.ownershipStatus === 0
        ? "blue"
        : item?.ownershipStatus === 1
        ? "green"
        : item?.ownershipStatus === -2
        ? "red"
        :item?.ownershipStatus === -1
        ?"yellow"
        : "red";
    let text =
      item?.ownershipStatus === 0
        ? "Requested"
        : item?.ownershipStatus === 1
        ? "Generated"
        : item?.ownershipStatus === -2
        ? "Error"
        :item?.ownershipStatus === -1
        ?"In Progress"
        : "Not Available";

    return (
      <>
        <label
          onClick={() => downloadOwnershipReport(item)}
          style={{
            cursor: item?.ownershipStatus === 1 ? " pointer" : "default",
          }}
        >
          <img
            src={
              item?.ownershipStatus === 0
                ? Request
                : item?.ownershipStatus === 1
                ? Download
                : item?.ownershipStatus === -2
                ? Failed
                : item?.ownershipStatus === -1
                ? Process
                : NotAvailable
            }
            alt="requeset icon"
          />
        </label>
        <div className="report-text-container ">
          <div className={`report-text ${color}`}>
            <label>{text}</label>
          </div>
        </div>
      </>
    );
  };

  const getStar = (item) => {
    if (tabs === "all") {
      let value = starAllData.find((star) => item.requestId === star.requestId);
      return value;
    } else {
      return true;
    }
  };

  const generateReport = async (payload) => {
    const { data, status } = await onRegenerateReport(payload?.requestId);
    if (status === 200) {
      console.log(data);
    }
  };

  return (
    <>
      <tr key={keyvalue}>
        <td className="tbody-first width-10 report-tost">
          <label className="checkBox">
            {!explorerTabs ? (
              <input
                type="checkbox"
                className="check"
                checked={item[item.requestId]}
                onChange={(e) => onChange(e, item)}
              />
            ) : null}
          </label>
          {!explorerTabs ? (
            <>
              <label
                className="image-wrap"
                onClick={() => onHandleStar(item, getStar(item))}
              >
                <img
                  src={getStar(item) ? StarActive : OutlineStar}
                  alt="Star Icon"
                />
              </label>
              {/* <div className="report-text-container">
                <div className="report-text brown">
                  <label>{item?.starred ? "Starred" : "Not Starred"}</label>
                </div>
              </div> */}
            </>
          ) : (
            <>
              <label
                className="image-wrap"
                onClick={() => onHandleArchive(item, "unarchive")}
              >
                <img src={UnArchive} alt="Star Icon" />
              </label>{" "}
              {/* <div className="report-text-container unarchive">
                <div className="report-text brown">
                  <label>unarchive</label>
                </div>
              </div> */}
            </>
          )}
        </td>
        <td className="width-15">{item?.propeyeId}</td>
        <td className="width-15">
          {item?.requestCreationDate
            ? moment(item?.requestCreationDate, "DD/MM/YYYY hh:mm:ss").format(
                "DD MMM YYYY hh:mm:ss"
              )
            : "-"}
        </td>
        <td className="width-20">{item?.region}</td>
        <td className="report-tost width-15">{getToolTip()}</td>
        <td className="report-tost width-15">
          {/* <label>
            <img src={Request} alt="requeset icon" />
          </label>
          <div className="report-text-container">
            <div className="report-text blue">
              <label>Requested</label>
            </div>
          </div> */}
          {getOwnershipToolTip()}
        </td>
        <td className="width-10">
          <div className={"more-dropdown dropdown"}>
            <div
              data-bs-toggle="dropdown"
              aria-expanded="false"
              aria-haspopup="true"
            >
              <label className="more-items">
                <svg
                  width="4"
                  height="18"
                  viewBox="0 0 4 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.99967 0.666504C2.4417 0.666504 2.86562 0.842099 3.17819 1.15466C3.49075 1.46722 3.66634 1.89114 3.66634 2.33317C3.66634 2.7752 3.49075 3.19912 3.17819 3.51168C2.86562 3.82424 2.4417 3.99984 1.99967 3.99984C1.55765 3.99984 1.13372 3.82424 0.821163 3.51168C0.508603 3.19912 0.333008 2.7752 0.333008 2.33317C0.333008 1.89114 0.508603 1.46722 0.821163 1.15466C1.13372 0.842099 1.55765 0.666504 1.99967 0.666504ZM1.99967 7.33317C2.4417 7.33317 2.86562 7.50877 3.17819 7.82133C3.49075 8.13389 3.66634 8.55781 3.66634 8.99984C3.66634 9.44186 3.49075 9.86579 3.17819 10.1783C2.86562 10.4909 2.4417 10.6665 1.99967 10.6665C1.55765 10.6665 1.13372 10.4909 0.821163 10.1783C0.508603 9.86579 0.333008 9.44186 0.333008 8.99984C0.333008 8.55781 0.508603 8.13389 0.821163 7.82133C1.13372 7.50877 1.55765 7.33317 1.99967 7.33317ZM1.99967 13.9998C2.4417 13.9998 2.86562 14.1754 3.17819 14.488C3.49075 14.8006 3.66634 15.2245 3.66634 15.6665C3.66634 16.1085 3.49075 16.5325 3.17819 16.845C2.86562 17.1576 2.4417 17.3332 1.99967 17.3332C1.55765 17.3332 1.13372 17.1576 0.821163 16.845C0.508603 16.5325 0.333008 16.1085 0.333008 15.6665C0.333008 15.2245 0.508603 14.8006 0.821163 14.488C1.13372 14.1754 1.55765 13.9998 1.99967 13.9998Z"
                    fill="#808080"
                  />
                </svg>
              </label>
            </div>
            <ul className="dropdown-menu">
              <li
                className={
                  item?.reportStatus !== 1
                    ? "dropdowns-item disable"
                    : "dropdowns-item"
                }
                onClick={() => {
                  if (item?.reportStatus === 1) {
                    setIsMapOpen(true);
                    setOpenMenu(!openMenu);
                    setMapViewData(item);
                  }
                }}
              >
                <label>
                  <img src={MapView} alt="map view" />
                </label>
                <span>Map View</span>
              </li>
              {/* <li
                className="dropdowns-item"
                onClick={() => {
                  setIsOpen(true);
                  setOpenMenu(!openMenu);
                }}
              >
                <label>
                  <img src={RaiseQuery} alt="raise query" />
                </label>
                <span>Raise Query</span>
              </li> */}
              <li
                className="dropdowns-item"
                onClick={() => generateReport(item)}
              >
                <label>
                  <img src={ReportRegenerate} alt="raise query" />
                </label>
                <span>Regenerate Report</span>
              </li>
              {!explorerTabs ? (
                <li
                  className="dropdowns-item"
                  onClick={() => {
                    onHandleArchive(item, "archive");
                    setOpenMenu(!openMenu);
                  }}
                >
                  <label>
                    <img src={Archive} alt="Archive" />
                  </label>
                  <span>Archive</span>
                </li>
              ) : null}
            </ul>
          </div>
        </td>
      </tr>

      <MapModalPopup
        mapModalIsOpen={mapModalIsOpen}
        setIsMapOpen={setIsMapOpen}
        mapViewData={mapViewData}
        setMapViewData={setMapViewData}
      />
      <ModalPopup modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} />
    </>
  );
};

export default ExplorerTable;
