import React from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";

import PrivateRoute from "./privateRoutes";
import Login from "../containers/login/login.jsx";
import Dashboard from "../containers/dashboard/dashboard.jsx";

const Routers = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/login" component={Login}></Route>
        <PrivateRoute exact path="/" component={Dashboard}></PrivateRoute>
      </Switch>
    </BrowserRouter>
  );
};

export default Routers;
