import React from "react";

import "./pagination.css";

import Left from "../../assets/images/table/left-arrow.svg";
import Right from "../../assets/images/table/right-arrow.svg";
import InActiveLeft from "../../assets/images/table/inactive-left-arrow.svg";
import InActiveRight from "../../assets/images/table/inactive-right-arrow.svg";

const Pagination = ({
  explorerTabs,
  pagination,
  setPagination,
  starPagination,
  setStarPagination,
  archivePagination,
  setArchivePagination,
}) => {
  var helper = 0;
  var currentLastRecord = 0;
  var currentFirstRecord = 0;
  var totalCount = 0;
  if (explorerTabs === "all") {
    helper = pagination.pageSize * pagination.currentPage;
    currentLastRecord =
      helper > pagination.totalPage ? pagination.totalPage : helper;
    currentFirstRecord = helper - pagination.pageSize + 1;
    totalCount = pagination.totalPage;
  } else if (explorerTabs === "star") {
    helper = starPagination.pageSize * starPagination.currentPage;
    currentLastRecord =
      helper > starPagination.totalPage ? starPagination.totalPage : helper;
    currentFirstRecord = helper - starPagination.pageSize + 1;
    totalCount = starPagination.totalPage;
  } else if (explorerTabs === "archive") {
    helper = archivePagination.pageSize * archivePagination.currentPage;
    currentLastRecord =
      helper > archivePagination.totalPage
        ? archivePagination.totalPage
        : helper;
    currentFirstRecord = helper - archivePagination.pageSize + 1;
    totalCount = archivePagination.totalPage;
  }

  const onHandlePress = (type) => {
    if (explorerTabs === "all") {
      if (type === "next") {
        setPagination((prev) => {
          return {
            ...prev,
            currentPage: pagination.currentPage + 1,
          };
        });
      } else {
        setPagination((prev) => {
          return {
            ...prev,
            currentPage: pagination.currentPage - 1,
          };
        });
      }
    } else if (explorerTabs === "star") {
      if (type === "next") {
        setStarPagination((prev) => {
          return {
            ...prev,
            currentPage: starPagination.currentPage + 1,
          };
        });
      } else {
        setStarPagination((prev) => {
          return {
            ...prev,
            currentPage: starPagination.currentPage - 1,
          };
        });
      }
    } else if (explorerTabs === "archive") {
      if (type === "next") {
        setArchivePagination((prev) => {
          return {
            ...prev,
            currentPage: archivePagination.currentPage + 1,
          };
        });
      } else {
        setArchivePagination((prev) => {
          return {
            ...prev,
            currentPage: archivePagination.currentPage - 1,
          };
        });
      }
    }
  };

  return (
    <div className="pagination-content">
      <div className="pagination-text">{`${currentFirstRecord} - ${currentLastRecord} of ${totalCount}`}</div>
      <button
        className="left"
        onClick={() => onHandlePress("prev")}
        disabled={currentFirstRecord === 1}
      >
        <img
          src={currentFirstRecord === 1 ? InActiveLeft : Left}
          alt="left click arrow"
        ></img>
      </button>
      <button
        className="right"
        onClick={() => onHandlePress("next")}
        disabled={helper >= totalCount}
      >
        <img
          src={helper >= totalCount ? InActiveRight : Right}
          alt="Right click arrow"
        />
      </button>
    </div>
    // </div>
  );
};

export default Pagination;
