import { decode } from "js-base64";
import crypto from "crypto";
// import { Buffer } from "buffer";

//const crypto = require('crypto')

export function rsaEncryption(data, key) {
  var publicKeyValue = decode(key);

  const encryptedData = crypto.publicEncrypt(
    {
      key: publicKeyValue,

      oaepHash: "sha256",
    },
    // We convert the data string to a buffer using `Buffer.from`
    Buffer.from(data)
  );
  return encryptedData.toString("base64");
}

// import EncryptRsa from 'encrypt-rsa';

// export function rsaEncryption(data,key){
//   var publicKeyValue = decode(key)
//   const encryptedText = encryptRsa.encryptStringWithRsaPublicKey({
//     text: data,
//     publicKeyValue,
//   });
//   return encryptedText
// }
