import React from "react";

import "./legend.css";

const Legend = () => {
  return (
    <div className="accordion" id="accordionExample">
      <div className="accordion-item">
        <h2 className="accordion-header" id="headingOne">
          <button
            className="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseOne"
            // aria-expanded="true"
            aria-controls="collapseOne"
          >
            Legend
          </button>
        </h2>
        <div
          id="collapseOne"
          className="accordion-collapse collapse "
          aria-labelledby="headingOne"
          data-bs-parent="#accordionExample"
        >
          <div className="accordion-body">
            <ul>
              <li className="legend-list">
                <label className="yellow"></label>
                <span>HDFC Marked Boundary</span>
              </li>
              <li className="legend-list">
                <label className="yellow-dark"></label>
                <span>HDFC Marked Point</span>
              </li>
              <li className="legend-list">
                <label className="red"></label>
                <span>Survey Boundary</span>
              </li>
              <li className="legend-list"> <label className="sky-blue"></label> <span>HDFC Bank</span> </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Legend;
