import React, { useEffect, useState } from "react";

import "./login.css";
import SatsureLogo from "../../assets/images/logo/satsure-new-logo.svg";
import HdfcLogo from "../../assets/images/hdfc-propeye-logo.svg";
import { useHistory } from "react-router-dom";

import { getLogin, getRsaKey } from "../../services/auth-services";
import { rsaEncryption } from "../../utils/encryption-helper";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [rsaKey, setRsaKey] = useState("");
  const [userData, setUserData] = useState({
    email: "",
    password: "",
  });
  useEffect(() => {
    fetchRsaKey();
  }, []);

  const fetchRsaKey = async () => {
    const { data, status } = await getRsaKey();
    if (status === 200) {
      setRsaKey(data?.data?.rsa);
    }
  };

  const onChangeHandler = (e) => {
    setUserData((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const onLogin = async () => {
    //localStorage.setItem("user", "sdfadsfasdfasdfasdfasdf");

    if (userData.email === "" || userData.email === undefined) {
    }
    if (userData.password === "" || userData.password === undefined) {
    }
    if (userData.email !== "" && userData.password !== "") {
      // let formData = new FormData();
      // formData.append("email", userData.email);
      // formData.append("password", userData.password);
      let userDetails = {
        email: rsaEncryption(userData.email, rsaKey),
        password: rsaEncryption(userData.password, rsaKey),
      };
      setLoading(true);
      const { data, status, error } = await getLogin(userDetails);
      if (status === 200) {
        setLoading(false);
        localStorage.setItem("user", JSON.stringify(data.data));
        history.push("/");
      } else {
        setLoading(false);
        console.log("login error", error);
      }
    }
  };

  return (
    <div className="login-container">
      <div className="login-wrapper">
        <div className="hdfc-logo-wrapper">
          <img src={HdfcLogo} alt="hdfc-logo" />
        </div>
        <div className="powerby-wrapper">
          <label htmlFor="">Powered By</label>
          <div className="satsure-logo-wrapper">
            <img src={SatsureLogo} alt="satsure-logo" />
          </div>
        </div>
        <div className="form">
          <div className="form-group">
            <label>Email Address</label>
            <div>
              <input
                type="email"
                name="email"
                value={userData.email}
                placeholder="xxxxx@gmail.com"
                className="form-control"
                onChange={(e) => onChangeHandler(e)}
              />
            </div>
          </div>
          <div className="form-group">
            <label>Password</label>
            <div>
              <input
                type="password"
                name="password"
                value={userData.password}
                placeholder="Password"
                className="form-control"
                onChange={(e) => onChangeHandler(e)}
              />
            </div>
          </div>
        </div>
        <div>
          {loading ? (
            <button className="btn-login">
              <div className="loader"></div>
            </button>
          ) : (
            <button className="btn-login" onClick={() => onLogin()}>
              Login
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;
