import React, { useEffect, useState, useRef } from "react";

import {
  MapContainer,
  ScaleControl,
  GeoJSON,
  Marker,
  FeatureGroup,
} from "react-leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import Control from 'react-leaflet-custom-control'
import L from "leaflet";
import Legend from "../../components/maplegend/legend";
import MapPopup from "../mappopup/mapPopup";
import { hdfcOfficeLocations } from "../../services/explorer-service";
// Css
import "./map.css";
import { MAP_API_KEY } from "../../utils/url-helper";

import { getMapPolygon } from "../../services/map-services";
import { toast } from "react-toastify";
import Pegman from "../pegman/pegman";
import { OnClickEvent } from "./OnClickEvent.js";

import RoutingMachine from "./RoutingMachine";

import { EditControl } from "react-leaflet-draw";
import * as turf from "@turf/turf";
// Image
import BankZoomIcon from '../../assets/images/bank-locations.svg';
import GeoLocZomIcon from '../../assets/images/zoom.svg';
import MapList from "../../assets/images/map-list.svg";
import Polygon from "../../assets/images/polygon.svg";
import distance from "../../assets/images/distance.svg";
import Routing from "../../assets/images/maproute.svg";
import HdfcMarkerIcon from "../../assets/images/hdfc-marker.svg"
import loadingGIF from "../../assets/images/loadingGIF.gif"

import "leaflet-draw/dist/leaflet.draw.css";

const Maps = ({ polygonId }) => {
  const mapRefe = useRef();
  const [show, setShow] = useState(false);
  const [measureList, setMeasureList] = useState(false);
  const [mapData, setMapdata] = useState([]);
  const [mapPolyData, setMapPolyData] = useState([]);
  const [map, setMaps] = useState(null);
  const [makingModeText, setMakingModeText] = useState("");
  const [directionValue, setDirectionValue] = useState([]);
  const [distanceKMeters, setDistanceKMeters] = useState("");
  const [routeDistanceKMeters, setRouteDistanceKMeters] = useState("");
  const [squareMeters, setSquareMeters] = useState("");
  const [squareFeet, setSquareFeet] = useState("");
  const [findRoute, setFindRoute] = useState(false);
  const [measureArea, setMeasureArea] = useState(false);
  const [polyLine, setPolyLine] = useState(false);
  const [hdfcOfficeValue, setHdfcOfficeValue] = useState([]);
  const [showLoader,setShowLoader]=useState(false);
  const [showhdfcOfficeLocation,setShowhdfcOfficeLocation]=useState(false);

  useEffect(() => {
    if (polygonId !== "") {
      getMapData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [polygonId]);

  useEffect(() => {
    if (mapData !== null) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapData]);
  useEffect(() => {
    if (directionValue.length === 2) {
      // let arr = [...directionValue];
      // var from = turf.point([arr[0].lat, arr[0].lng]);
      // var to = turf.point([arr[1].lat, arr[1].lng]);
      // var distance = turf.distance(from, to, { units: "kilometers" });
      // setRouteDistanceKMeters(distance.toFixed(2));
      setShow(true);
      setDistanceKMeters("");
      setSquareMeters("");
      setSquareFeet("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [directionValue]);

  const hdfcOfficeData = async () => {
    try {
      setShowLoader(true);
      const { data, status } = await hdfcOfficeLocations();
      console.log(data,"here")

      if (status === 200) {
        setShowLoader(false);
        setHdfcOfficeValue(data);
        map.flyTo([20.5937, 78.9629], 5, {
          duration: 1.5,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
  const hdfcIcon = () => {
    return L.icon({
      iconUrl: HdfcMarkerIcon,
      iconSize: [25, 25],
    });
  };

  const polygonGetLoc = (ev) => {
    var direct = [];

    const latit = ev.lat;
    const long = ev.lng;

    var directObj = {
      lat: latit,
      lng: long,
    };

    direct = direct.concat(directObj);
    getDirection(direct);
  };

  const getDirection = (direct) => {
    if (directionValue.length !== 2) {
      var directionArr = directionValue.concat(direct);
      setDirectionValue(directionArr);
    }
  };
  const getMapData = async () => {
    const { data, status } = await getMapPolygon(polygonId);
    if (status === 200) {
      setMapdata(data.data);
    }
  };

  const fetchData = () => {
    if (mapData.length !== 0) {
      let polygonGeoBoundry = [];
      mapData.map((item) => {
        polygonGeoBoundry.push({
          datavalue: item?.id,
          coordinates: item?.centroid ? JSON.parse(item?.centroid) : null,
          polygon: {
            type: "FeatureCollection",
            features: [
              {
                datavalue: item,
                type: "Feature",
                properties: {
                  name: item?.murabba + "//" + item?.khasara,
                },
                geometry: item?.geometry ? JSON.parse(item.geometry) : null,
                centroid: item?.centroid ? JSON.parse(item.centroid) : null,
              },
            ],
          },
        });
        return null;
      });

      setMapPolyData([...polygonGeoBoundry]);
      let checkBoundary = polygonGeoBoundry.filter(
        (item) => item?.coordinates !== null
      );
      if (
        checkBoundary.length !== 0 &&
        checkBoundary[0]?.coordinates?.coordinates !== undefined
      ) {
        let latlng = checkBoundary[0]?.coordinates?.coordinates.reverse();
        if (map)
          map.flyTo(latlng, 16, {
            duration: 1.5,
          });
      } else {
        toast.error("No Boundaries Detected");
      }
    }
  };

  const onEachCentroid = (data, layer) => {
    layer.bindTooltip(` 
    <div class="popup-wrapper">
    <div class="heading">
      <h1>Centroid : </h1>
      <label>${data.centroid.coordinates[1].toFixed(
      5
    )}N ${data.centroid.coordinates[0].toFixed(5)}E</label>
    </div>
    <div class="popup-body">
      <h1>Survey Details:</h1>
      <ul>
        <li>
          <label>${data?.datavalue?.levelFive?.label}</label>
          <label>${data?.datavalue?.levelFive?.value || "-"}</label>
        </li>
        ${data.datavalue?.levelSix
        ? `<li>
          <label>${data.datavalue?.levelSix?.label}</label>
          <label>${data?.datavalue?.levelSix?.value || "-"}</label>
        </li>`
        : ""
      }
        ${data.datavalue?.levelSeven
        ? `<li>
          <label>${data.datavalue?.levelSeven?.label}</label>
          <label>${data?.datavalue?.levelSeven?.value || "-"}</label>
        </li>`
        : ""
      }
       
      </ul>
    </div>
  </div>
   `);
  };

  const onEdit = (e) => { };
  const onCreate = (e) => {
    const { layerType, layer } = e;

    if (layerType === "polygon") {
      let arr = [];
      let latLng = layer.getLatLngs()[0];
      latLng.map((item) => {
        arr.push([item.lng, item.lat]);
        return null;
      });

      let polygon = {
        type: "FeatureCollection",
        features: [
          {
            datavalue: 1,
            type: "Feature",
            geometry: {
              type: "Polygon",
              coordinates: [arr],
            },
            centroid: "",
          },
        ],
      };
      let areaValue = turf.area(polygon) * 10.76391042;
      setShow(true);
      setDistanceKMeters("");
      setShow(true);
      setSquareMeters(turf.area(polygon).toFixed(2));
      setSquareFeet(areaValue.toFixed(2));
      setRouteDistanceKMeters("");
      setDistanceKMeters("");
    } else if (layerType === "polyline") {
      var tempLatLng = null;
      var totalDistance = 0.0;

      layer.getLatLngs().forEach(function (latlng) {
        if (tempLatLng == null) {
          tempLatLng = latlng;
          return;
        }
        totalDistance += tempLatLng.distanceTo(latlng);
        tempLatLng = latlng;
        return null;
      });
      let totalDistanceValue = (totalDistance / 1000).toFixed(3);
      setDistanceKMeters(totalDistanceValue);
      setShow(true);
      setSquareMeters("");
      setSquareFeet("");
      setRouteDistanceKMeters("");
    }
  };
  const onDeleted = () => { };
  return (
    <>
      <MapContainer
        id="map"
        ref={mapRefe.current}
        google={window.google}
        center={[20.593, 78.9629]}
        zoom={5}
        onClick={polygonGetLoc}
        whenCreated={(map) => {
          setMaps(map);
        }}
      >
        <ReactLeafletGoogleLayer
          apiKey={MAP_API_KEY}
          type={"hybrid"}
          useGoogMapsLoader={true}
        />
        { showLoader && 
         <div className="loader_gif">
          <img src={loadingGIF} alt="loading.." height="30px" width="30px"/>
          </div>
          }
        {findRoute && <OnClickEvent polygonGetLoc={polygonGetLoc} />}
        {hdfcOfficeValue.data &&
          hdfcOfficeValue.data.length !== 0 &&
          showhdfcOfficeLocation &&
          hdfcOfficeValue.data.map((mark) => {
            console.log(mark,"here")
            return <Marker position={[mark.Latitude, mark.Longitude]} icon={hdfcIcon()} />;
          })}
        <Legend />
        {measureArea && (
          <FeatureGroup>
            <EditControl
              position="topleft"
              onEdited={onEdit}
              onCreated={onCreate}
              onDeleted={onDeleted}
              draw={{
                rectangle: false,
                circle: false,
                circlemarker: false,
                marker: false,
                polyline: false,
                polygon: true,
              }}
            />
          </FeatureGroup>
        )}
        {polyLine && (
          <FeatureGroup>
            <EditControl
              position="topleft"
              onEdited={onEdit}
              onCreated={onCreate}
              onDeleted={onDeleted}
              draw={{
                rectangle: false,
                circle: false,
                circlemarker: false,
                marker: false,
                polyline: true,
                polygon: false,
              }}
            />
          </FeatureGroup>
        )}

        {directionValue.map((data, key) => {
          return (
            <Marker
              position={data}
              zIndex={99999}
              fontSize={20}
              fontWeight="bold"
            />
          );
        })}
        {directionValue.length === 2 && (
          <RoutingMachine
            wayPoints={directionValue}
            setDistanceKMeters={setDistanceKMeters}
          />
        )}
        {mapPolyData &&
          mapPolyData !== undefined &&
          mapPolyData.length !== 0 &&
          mapPolyData.map((item) => {
            return (
              <GeoJSON
                key={item.datavalue}
                data={item.polygon}
                style={() => ({
                  color: "#D10000",
                  fillOpacity: 0,
                  weight: 5,
                  opacity: 0.7,
                  dashArray: "7",
                })}
                onEachFeature={(cent, layer) => onEachCentroid(cent, layer)}
              />
            );
          })}
        <MapPopup
          show={show}
          setShow={setShow}
          squareMeters={squareMeters}
          squareFeet={squareFeet}
          makingModeText={makingModeText}
          distanceKMeters={distanceKMeters}
          routeDistanceKMeters={routeDistanceKMeters}
          setSquareMeters={setSquareMeters}
          setSquareFeet={setSquareFeet}
          setDistanceKMeters={setDistanceKMeters}
          setRouteDistanceKMeters={setRouteDistanceKMeters}
          setDirectionValue={setDirectionValue}
        />

        <Pegman />
        <ScaleControl position="bottomleft" />

        <Control position="topleft">
          <button className="map-list-order" onClick={() => {
            fetchData();
          }}>
            <div className="map-icon">
              <img src={GeoLocZomIcon} alt="Map Icon" />
            </div>
          </button>
          <button className="map-list-order" onClick={()=>{
            setShowhdfcOfficeLocation(!showhdfcOfficeLocation)
            if(!showhdfcOfficeLocation)
            hdfcOfficeData();
          }}>
            <div className="zoom-minus">
              <img src={BankZoomIcon} alt="Bank Location Icon" />
            </div>
          </button>
          <button className="map-list-order dropdown">
            <div
              data-bs-toggle="dropdown"
              aria-expanded="false"
              aria-haspopup="true" className="measu-poly-route">
              <img
                src={MapList}
                alt="map list icon"
              /></div>
            <ul className="dropdown-menu icon-order">

              <li
                onClick={() => {
                  setPolyLine(false);
                  setMeasureList(false);
                  setMeasureArea(true);
                  setFindRoute(false);
                  setMakingModeText("Area of the Polygon");
                }}
              >
                <label>
                  <img src={Polygon} alt="map list icon" />
                </label>
                <span>Measure Area</span>
              </li>
              <li
                onClick={() => {
                  setMeasureArea(false);
                  setMeasureList(!measureList);
                  setPolyLine(true);
                  setFindRoute(false);
                  setMakingModeText("Distance");
                }}
              >
                <label>
                  <img src={distance} alt="map list icon" />
                </label>
                <span>Measure Distance</span>
              </li>
              <li
                onClick={() => {
                  setFindRoute(true);
                  setMeasureList(false);
                  setPolyLine(false);
                  setMeasureArea(false);
                  setMakingModeText("Route Distance");
                }}
              >
                <label>
                  <img src={Routing} alt="map list icon" />
                </label>
                <span>Measure Route</span>
              </li>
            </ul>
          </button>
        </Control>
      </MapContainer>
      {/* <div className="measure-poly-area">
        <div className="list-icon" onClick={() => setMeasureList(!measureList)}>
          <label>
            <img
              src={MapList}
              // src={measureList ? MapList : MapListActive}
              alt="map list icon"
            />
          </label>
        </div>
        {measureList === true ? (
          <ul className="icon-order">
            <li
              onClick={() => {
                setPolyLine(false);
                setMeasureList(false);
                setMeasureArea(true);
                setFindRoute(false);
                setMakingModeText("Area of the Polygon");
              }}
            >
              <label>
                <img src={Polygon} alt="map list icon" />
              </label>
              <span>Measure Area</span>
            </li>
            <li
              onClick={() => {
                setMeasureArea(false);
                setMeasureList(!measureList);
                setPolyLine(true);
                setFindRoute(false);
                setMakingModeText("Distance");
              }}
            >
              <label>
                <img src={distance} alt="map list icon" />
              </label>
              <span>Measure Distance</span>
            </li>
            <li
              onClick={() => {
                setFindRoute(true);
                setMeasureList(false);
                setPolyLine(false);
                setMeasureArea(false);
                setMakingModeText("Route Distance");
              }}
            >
              <label>
                <img src={Routing} alt="map list icon" />
              </label>
              <span>Measure Route</span>
            </li>
          </ul>
        ) : null}
      </div> */}
    </>
  );
};

export default Maps;
