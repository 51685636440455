import React, { useEffect } from "react";
import L from "leaflet";
import "leaflet-pegman/leaflet-pegman.min.js";
import "leaflet-pegman/leaflet-pegman.min.css";
import { useMap } from "react-leaflet";
const Pegman = () => {
  const map = useMap();

  useEffect(() => {
    var pegmanLayer = new L.Control.Pegman({
      position: "topleft", // position of control inside the map
      theme: "leaflet-pegman-v3-small",
    });
    pegmanLayer.addTo(map);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

// clear the previous map boundery on updation.
// return () => {
//   map.removeLayer(mapBoundery);
// };

export default Pegman;
