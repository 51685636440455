import React from "react";

import SelectFilter from "../selectfilter/selectfilter";

import HdfcLogo from "../../assets/images/hdfc-propeye-logo.svg";
import SatsureLogo from "../../assets/images/logo/satsure-new-logo.svg";
import Star from "../../assets/images/star.svg";
import List from "../../assets/images/list.svg";
import Archive from "../../assets/images/archive.svg";

import "./sidebar.css";

import AnalyticsFilter from "../analyticsFilter/analyticsfilter";

const Sidebar = ({
  tabs,
  getSearchBoundary,
  explorerTabs,
  setExplorerTabs,
  search,
  setSearch,
  setPostData,
}) => {
  const getBoundary = (id) => {
    getSearchBoundary(id);
  };

  const getPostData = (data) => {
    setPostData(data);
  };

  return (
    <>
      <div className="sidebar-container">
        <div className="filters">
          <div className="logo-wrapper">
            <img src={HdfcLogo} alt="hdfc-logo" />
          </div>
          {tabs === "explorer" && (
            <>
              <div className="table-selectors">
                <div className="filter-group search-input">
                  <input
                    placeholder="Search"
                    type="text"
                    className="search-filter"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
                <div
                  className={
                    explorerTabs === "all"
                      ? "selector-wrapper active"
                      : "selector-wrapper"
                  }
                  onClick={() => setExplorerTabs("all")}
                >
                  <div className="select-icons">
                    <img src={List} alt="list icon" />
                  </div>
                  <label> ALL FILES</label>
                </div>
                <div
                  className={
                    explorerTabs === "star"
                      ? "selector-wrapper active"
                      : "selector-wrapper"
                  }
                  onClick={() => setExplorerTabs("star")}
                >
                  <div className="select-icons">
                    <img src={Star} alt="star-icon" />
                  </div>
                  <label>STARRED</label>
                </div>
                <div
                  className={
                    explorerTabs === "archive"
                      ? "selector-wrapper active"
                      : "selector-wrapper"
                  }
                  onClick={() => setExplorerTabs("archive")}
                >
                  <div className="select-icons">
                    <img src={Archive} alt="archive icon" />
                  </div>
                  <label>ARCHIVED</label>
                </div>
                <hr />
              </div>

              {/* <ExplorerFilter
                setDateRange={getDateRange}
                setApplyFun={getApplyFun}
              /> */}
            </>
          )}
          {tabs === "locator" && (
            <>
              <SelectFilter getSearchBoundary={getBoundary} />
            </>
          )}
          {tabs === "analytics" && (
            <AnalyticsFilter getPostData={getPostData} />
          )}
        </div>
        <div className="powerby">
          <label htmlFor="">Powered By</label>
          <div className="satsure-logo-wrapper">
            <img src={SatsureLogo} alt="satsure-logo" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
